<template>
  <div :class="containClass">
    <!-- Start Row 1  -->
    <v-expand-transition>
      <div v-if="advanced || multi || forceAdvanced">
        <v-row dense>
          <v-col cols="12">
            <v-text-field
              label="Name"
              v-model="window.name"
              :disabled="disabled"
              color="color3"
              hint="i.e. Early bird, Open, Late"
              persistent-hint
              @input="onNameChange"
            >
              <v-btn
                v-if="!mySimplicity.name"
                slot="append-outer"
                color="warning"
                fab x-small
                @click.stop="$emit('warning-click', 'Window Name')"
              >
                <v-icon>fas fa-exclamation</v-icon>
              </v-btn>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row dense v-if="false">
            <v-checkbox
              label="Pre-registration"
              v-model="window.canPayAtEvent"
              color="success"
              class="shrink mr-2 mt-0" hide-details
              :disabled="disabled"
              @change="onCanPayAtEventChange"
            >
              <template v-slot:label>
                Pre-registration
                <v-btn
                  v-if="!mySimplicity.canPayAtEvent"
                  slot="append-outer"
                  color="warning"
                  class="ml-2"
                  fab x-small
                  @click.stop="$emit('warning-click', 'Pre-registration status')"
                >
                  <v-icon>fas fa-exclamation</v-icon>
                </v-btn>
              </template>
            </v-checkbox>
            <v-slide-x-transition>
              <v-checkbox
                label="Require payment info"
                v-model="window.canProcessPayment"
                v-if="window.canPayAtEvent"
                class="shrink mr-2 mt-0"
                color="success" hide-details
                :disabled="disabled"
                @change="onCanProcessPaymentChange"
              >
                <template v-slot:label>
                  Require payment info
                  <v-btn
                    v-if="!mySimplicity.canProcessPayment"
                    slot="append-outer"
                    color="warning"
                    class="ml-2"
                    fab x-small
                    @click.stop="$emit('warning-click', 'payment requirments')"
                  >
                    <v-icon>fas fa-exclamation</v-icon>
                  </v-btn>
                </template>
              </v-checkbox>
            </v-slide-x-transition>
        </v-row>
        <v-row dense v-if="false">
          <v-col cols="12">
            <v-alert type="warning" :value="window.canPayAtEvent" text dense border="left" transition="scale-transition">
              Payment will not be processed
            </v-alert>
          </v-col>
        </v-row>
      </div>
    </v-expand-transition>
    <!-- Calculator  -->
    <v-row dense justify="end">
      <v-btn
        color="color3"
        @click.stop="calcDialog = true"
        text icon
        v-if="!disabled"
      >
        <v-icon>fas fa-calculator</v-icon>
      </v-btn>
      <v-dialog
        v-model="calcDialog"
        scrollable
        max-width="500px"
        transition="dialog-transition"
      >
        <v-card>
          <fee-calculator
            :username="tournament.organization.username"
            :tournament="tournament"
            :visible="calcDialog"
            :window="window"
            @new-fee="onNewFee"
            @close-click="calcDialog = false"
          ></fee-calculator>
        </v-card>
      </v-dialog>
    </v-row>
    <!-- Fee  -->
    <v-row dense>
      <v-col cols="12" sm="6">
        <v-text-field
          v-model="window.fee"
          label="Entry Fee*"
          type="number"
          prepend-icon="fas fa-dollar-sign"
          @blur="formatCurrency"
          :disabled="disabled"
          @input="onFeeChange"
          color="color3"
          hide-details
        >
          <v-btn
            v-if="!mySimplicity.fee"
            slot="append-outer"
            color="warning"
            fab x-small
            @click.stop="$emit('warning-click', 'Entry Fee')"
          >
            <v-icon>fas fa-exclamation</v-icon>
          </v-btn>
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="6" v-if="!hidePer">
        <v-radio-group hide-details v-model="window.feeIsPerTeam" row @change="onFeeIsPerTeamChange">
          <v-radio label="Per Team" :value="true" :disabled="disabled" color="color3"></v-radio>
          <v-radio label="Per Player" :value="false" :disabled="disabled" color="color3"></v-radio>
        </v-radio-group>
      </v-col>
      <v-col cols="12" class="d-flex">
        <v-checkbox
          color="success"
          label="Allow TBD"
          v-if="(tournament.isTournament || tournament.isLeague) && !(division && division.allowZeroPlayers)"
          v-model="window.isEarly"
          :value="true"
          :disabled="disabled || !!tournament.externalRegistrationUrl"
          class="shrink"
        ></v-checkbox>
        <v-checkbox
          color="success"
          label="Do NOT require full team payment"
          v-if="!window.feeIsPerTeam && (division && division.numAllowedOnRoster > 1) && (window.isEarly || (division && division.allowZeroPlayers))"
          v-model="window.isLate"
          :value="true"
          :disabled="disabled || !!tournament.externalRegistrationUrl"
          class="shrink ml-2"
        ></v-checkbox>
        <v-slide-x-reverse-transition>
          <v-checkbox
            v-if="showDepositOption"
            label="Allow deposit or partial payment"
            v-model="allowDeposit"
            :disabled="disabled"
            color="success"
            class="ml-3"
          ></v-checkbox>
        </v-slide-x-reverse-transition>
      </v-col>
    </v-row>
    <v-expand-transition>
      <v-row v-if="allowDeposit">
        <v-col cols="12">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="6" md="3">
                  <v-text-field
                  v-model="window.minAmountDue"
                  label="Minimum Amount Due*"
                  type="number"
                  prepend-icon="fas fa-dollar-sign"
                  @blur="formatCurrency"
                  :disabled="disabled"
                  @input="onFeeChange"
                  color="color3"
                  hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="6" md="3">
                  <v-text-field
                    label="Minimum Amount Display Label"
                    v-model="window.minAmountLabel"
                    color="color3"
                    hide-details
                    :disabled="disabled"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    label="Minimum Amount Agreement Text"
                    v-model="window.minAmountAgreement"
                    color="color3"
                    hide-details
                    :disabled="disabled"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-expand-transition>
    <!-- Start Row 2  -->
    <date-time-picker
      v-if="!noDates"
      :datetime.sync="window.dtStart"
      label="Open"
      :disabled="disabled"
      :warning="{date: !mySimplicity.dateStart, time: !mySimplicity.timeStart}"
      @warning-click="$emit('warning-click', $event)"
      @update:datetime="onDtStartChange"
    ></date-time-picker>
    <date-time-picker
      v-if="!noDates"
      :datetime.sync="window.dtEnd"
      label="Close"
      :disabled="disabled"
      :warning="{date: !mySimplicity.dateEnd, time: !mySimplicity.timeEnd}"
      @warning-click="$emit('warning-click', $event)"
      @update:datetime="onDtEndChange"
    ></date-time-picker>
    <v-row dense justify="end" v-if="multi">
      <v-btn
        color="color3"
        text
        icon
        x-small
        v-if="!disabled"
        @click.stop="$emit('delete-click')"
      >
        <v-icon>fas fa-trash</v-icon>
      </v-btn>
    </v-row>
  </div>
</template>

<script>
import moment from 'moment'
import DateTimePicker from '../../Utils/DateTimePicker.vue'
import FeeCalculator from '@/components/Organization/FeeCalculator'

export default {
  props: ['window', 'beforeDate', 'disabled', 'hideDates', 'simplicity', 'dialog', 'multi', 'advanced', 'hidePer', 'tournament', 'division', 'orgProps'],
  data () {
    return {
      regStart: false,
      regStartT: false,
      regEnd: false,
      regEndT: false,
      calcDialog: false
    }
  },
  computed: {
    forceAdvanced () {
      return !!this.window.name
    },
    noDates () {
      return this.hideDates !== undefined && this.hideDates !== false
    },
    mySimplicity () {
      return this.simplicity || {
        fee: true,
        dateStart: true,
        timeStart: true,
        dateEnd: true,
        timeEnd: true,
        name: true,
        canPayAtEvent: true,
        canProcessPayment: true
      }
    },
    containClass () {
      return this.multi || this.advanced ? 'grey lighten-3 pa-4 mb-4' : ''
    },
    allowDeposit: {
      get: function () {
        return !!this.window.minAmountLabel
      },
      set: function (v) {
        if (!v) {
          this.window.minAmountDue = null
          this.window.minAmountLabel = null
          this.window.minAmountAgreement = null
        } else {
          this.window.minAmountLabel = 'Deposit Only'
        }
      }
    },
    showDepositOption () {
      const a = this.window.minAmountDue !== null
      const b = this.advanced || this.forceAdvanced
      const c = this.orgProps && this.orgProps.includes('partialPayments')
      return a || (b && c)
    }
  },
  methods: {
    onNewFee (fee) {
      this.window.fee = fee
      this.formatCurrency()
      this.calcDialog = false
    },
    formatCurrency () {
      const fee = parseFloat(this.window.fee)
      this.window.fee = isNaN(fee) ? null : fee.toFixed(2)
      this.onFeeChange()
    },
    regAllowedOpen (date) {
      if (!this.beforeDate) return true
      return moment(date) <= moment(this.beforeDate)
    },
    regAllowedClose (date) {
      if (!this.beforeDate) return true
      if (!this.window.startDate) return true
      return moment(date) <= moment(this.beforeDate) && moment(date) >= moment(this.window.startDate)
    },
    onFeeChange () {
      if (this.window.emit) this.$emit('change', { field: 'fee', value: this.window.fee })
    },
    onFeeIsPerTeamChange () {
      if (this.window.emit) this.$emit('change', { field: 'feeIsPerTeam', value: this.window.feeIsPerTeam })
    },
    onDtStartChange () {
      if (this.window.emit) this.$emit('change', { field: 'dtStart', value: this.window._dtStart })
    },
    onDtEndChange () {
      if (this.window.emit) this.$emit('change', { field: 'dtEnd', value: this.window._dtEnd })
    },
    onNameChange () {
      if (this.window.emit) this.$emit('change', { field: 'name', value: this.window.name })
    },
    onCanPayAtEventChange () {
      if (this.window.emit) this.$emit('change', { field: 'canPayAtEvent', value: this.window.canPayAtEvent })
    },
    onCanProcessPaymentChange () {
      if (this.window.emit) this.$emit('change', { field: 'canProcessPayment', value: this.window.canProcessPayment })
    }
  },
  watch: {
    dialog: function (n) {
      this.window.emit = !n
    },
    'window.canPayAtEvent': function (val) {
      if (!val) {
        this.window.canProcessPayment = false
      }
    }
  },
  components: {
    DateTimePicker,
    FeeCalculator
  }
}
</script>
