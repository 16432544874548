<template>
  <div>
    <v-select
      :items="choices"
      v-model="bodies"
      label="Sanctioning Body"
      :disabled="disabled"
      color="color3"
      item-color="color3"
      :loading="loading"
      multiple
      chips small-chips deletable-chips
    ></v-select>
    <v-dialog
      v-model="block"
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card class="pa-3 title text-center">
          <div>To sanction this tournament as {{blocked}},</div>
          <div>please contact us.</div>
          <v-btn color="success" @click="block = false">OK</v-btn>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: ['tournament', 'disabled'],
  data () {
    return {
      block: false,
      blocked: null,
      loading: false
    }
  },
  computed: {
    ...mapGetters(['user']),
    allowMulti () {
      return !this.bodies.includes('AAU')
    },
    p1440Elite () {
      return this.user.vbl || !!this.user.pages.find(f => f.username === 'p1440')
    },
    org () {
      return this.tournament && this.tournament.organization && this.user && this.user.pages.find(f => f.id === this.tournament.organization.id)
    },
    sb () {
      return this.tournament.sanctioningBody
    },
    choices () {
      // const isAAU = this.primary && this.primary === 'AAU'
      // const noAAU = this.primary && this.primary !== 'AAU'
      return [
        { text: 'AAU', value: 'AAU', disabled: false },
        { text: 'AVP', value: 'AVPA', disabled: false },
        { text: 'BVCA', value: 'BVCA', disabled: false },
        { text: 'BVNE', value: 'BVNE', disabled: false },
        { text: 'JVA', value: 'JVA', disabled: false },
        { text: 'p1440', value: 'p1440', disabled: false },
        { text: 'p1440 Elite', value: 'p1440Elite', hide: true },
        { text: 'USAV', value: 'USAV', disabled: false },
        { text: 'None', value: null }
      ].filter(f => !f.hide)
    },
    primary () {
      return this.tournament.sanctioningBody
    },
    nonPrimary () {
      return this.tournament.secondarySBs
    },
    bodies: {
      get () {
        var a = []
        this.primary && a.push(this.primary)
        this.nonPrimary.length && a.push(...this.nonPrimary)
        return [this.primary, ...this.nonPrimary]
      },
      set (v) {
        if (v.length === 0) v.push(null)
        this.tournament.sanctioningBody = null
        this.choices.forEach(f => this.tournament.setProp(`dualSanction-${f.value}`, false))
        const hasNull = v.includes(null)
        const nullFirst = v[0] === null
        const clear = hasNull && !nullFirst
        if (clear) {
          this.tournament.sanctioningBody = null
          return
        }
        if (nullFirst && v.length > 1) v.shift()

        let primary = v[0]
        if (v.includes('BVCA')) {
          primary = 'BVCA'
        } else if (v.includes('BVNE')) {
          primary = 'BVNE'
        } else if (v.includes('p1440')) {
          primary = 'p1440'
        }
        this.tournament.sanctioningBody = primary
        const secondary = v.filter(f => f !== primary)
        if (secondary.length > 0) {
          secondary.forEach(f => this.tournament.setProp(`dualSanction-${f}`, true))
        }
      }
    }
  },
  methods: {
    checkStatus () {
      const sb = this.tournament.sanctioningBody
      const block = this.org && sb && this.org.props.includes(`block-${sb.toLowerCase()}`)
      if (block) {
        this.blockFrom(sb)
      }
      if (sb && sb.startsWith('BV')) this.checkAffiliate(sb)
    },
    blockFrom (sb) {
      this.block = true
      this.blocked = sb
      this.bodies = this.bodies.filter(f => f !== sb)
    },
    checkAffiliate (sb) {
      if ([177, 179].includes(this.org.id) && sb === 'BVNE') return
      if ([719].includes(this.org.id) && sb === 'BVCA') return

      this.loading = true
      this.$VBL.organization.affiliates.check(this.org.username, sb)
        .then(r => {
          if (!r.data) {
            this.blockFrom(sb)
          }
        })
        .catch(e => console.log(e.response))
        .finally(() => { this.loading = false })
    }
  },
  watch: {
    sb: 'checkStatus',
    'org.id': 'checkStatus'
  }
}
</script>
