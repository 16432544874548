export default class FieldChoice {
  list = ''
  simple = true
  show = true
  skipAsk = false

  constructor (label, value, deps, skipAsk) {
    this.label = label
    this.value = value
    this.deps = deps
    this.skipAsk = !!skipAsk
  }

  get description () {
    return this.list === 'fields' ? `Ask For ${this.label}`
      : this.list === 'requiredFields' ? `Require ${this.label}`
        : `Skip asking for ${this.label}`
  }
}
