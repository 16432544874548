var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"scrollable":"","max-width":"500px","transition":"dialog-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"xsmall",attrs:{"color":"color3","text":""},on:{"click":function($event){$event.stopPropagation();_vm.dialog = true}}},on),[_c('v-icon',{staticClass:"mr-2"},[_vm._v("fas fa-external-link-alt")]),_vm._v(" "+_vm._s(_vm.tournament.externalRegistrationUrl ? 'Edit' : 'Add')+" External Registration ")],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"color1 color1Text--text"}},[_c('v-toolbar-title',[_vm._v("External Registration")])],1),_c('v-container',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"type":"info","value":true}},[_vm._v(" By adding an external registration URL, you will be turning off all VolleyballLife.com's registration features. Including, but not limited to: registration deadlines, automatic refund/withdraw, AAU# validation, player profile mapping, tournament team contact, and automatic wait-listing. ")])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-checkbox',{attrs:{"color":"color3","label":"I understand I will be losing all registration features"},model:{value:(_vm.verified),callback:function ($$v) {_vm.verified=$$v},expression:"verified"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"name":"url","label":"External Registration URL*","error-messages":_vm.urlErrors,"hint":"Add the complete URL i.e. https://www.myregistration.com?tournament=0","persistent-hint":"","disabled":!_vm.verified},on:{"blur":function($event){return _vm.$v.url.$touch()}},model:{value:(_vm.url),callback:function ($$v) {_vm.url=$$v},expression:"url"}})],1),_c('v-col',{attrs:{"cols":"4"}},[(_vm.tournament.externalRegistrationUrl)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-fab-transition',[_c('v-btn',_vm._g({staticClass:"mr-0",attrs:{"color":"white error--text","fab":""},on:{"click":function($event){$event.stopPropagation();return _vm.clear($event)}}},on),[_c('v-icon',[_vm._v("fas fa-ban")])],1)],1)]}}],null,false,751547290)},[_c('span',[_vm._v("Remove")])]):_vm._e()],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"8"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-fab-transition',[(_vm.saveable)?_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"color":"color3 color3Text--text","fab":""},on:{"click":function($event){$event.stopPropagation();return _vm.save($event)}}},on),[_c('v-icon',[_vm._v("fas fa-save")])],1):_vm._e()],1)]}}])},[_c('span',[_vm._v("Save")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-fab-transition',[_c('v-btn',_vm._g({attrs:{"color":"error white--text","fab":""},on:{"click":function($event){$event.stopPropagation();_vm.dialog = false}}},on),[_c('v-icon',[_vm._v("fas fa-times")])],1)],1)]}}])},[_c('span',[_vm._v("Cancel")])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }