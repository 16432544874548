var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.divisionHeaders,"items":_vm.divisions,"item-key":"key","hide-default-footer":"","dense":"","options":{itemsPerPage: -1}},scopedSlots:_vm._u([{key:"item.division.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((item.isTournament || item.isTryout || item.isLeague) && item.division && !item.isCS ? item.division.name : null)+" ")]}},{key:"item.ageType.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.ageType && !item.isCS ? item.ageType.name : null)+" ")]}},{key:"item.gender.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.gender && !item.isCS ? item.gender.name : null)+" ")]}},{key:"item.maxTeams",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.maxTeams)+" "),(!item.maxTeams)?_c('v-icon',[_vm._v("fas fa-infinity")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(!_vm.viewOnly)?_c('v-btn',{staticClass:"mr-1",attrs:{"fab":"","small":"","text":"","color":"color3","loading":_vm.busy},on:{"click":function($event){return _vm.editDivision(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-pencil-alt")])],1):_vm._e(),(!_vm.viewOnly && _vm.divisions.length > 1)?_c('v-btn',{attrs:{"fab":"","small":"","text":"","color":"error","loading":_vm.busy},on:{"click":function($event){return _vm.deleteDivision(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-trash")])],1):_vm._e()]}}],null,true)})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12"}},[(!_vm.viewOnly)?_c('v-btn',{staticClass:"color3--text",attrs:{"text":"","loading":_vm.busy},on:{"click":function($event){$event.stopPropagation();return _vm.addDivision($event)}}},[_vm._v(" Add A Division ")]):_vm._e()],1),_c('v-dialog',{attrs:{"max-width":"500px","persistent":"","scrollable":""},model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}},[(_vm.selectedDivision)?_c('v-card',[_c('v-toolbar',{attrs:{"color":"color2 color2Text--text"}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.selectedDivision.id > 0 ? 'Edit Division' : 'Add New Division'))])],1),_c('v-card-text',[(_vm.selectedDivision)?_c('division-simple',{attrs:{"division":_vm.selectedDivision,"isNew":_vm.isNew,"dialog":_vm.editDialog,"options":_vm.options,"tournament":_vm.tournament,"showCoachReg":_vm.showCoachReg},on:{"save":_vm.saveDivision,"cancel":_vm.cancelEdit}}):_vm._e()],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }