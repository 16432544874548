<template>
  <v-dialog
    v-model="dialog"
    scrollable
    persistent
    max-width="500px"
    transition="dialog-transition"
  >
    <v-card>
      <v-toolbar color="color1 color1Text--text">
        <v-toolbar-title>Location Wizardx</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="color2 color2Text--text" small fab @click.stop="dialog = false">
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pa-0">
        <v-window v-model="currentStep" touchless>
          <!-- Find it on the map -->
          <v-window-item :value="0">
            <v-container class="pa-1">
              <v-row dense>
                <v-col cols="12" class="subheading">
                  Let's find your location on the map
                </v-col>
                <v-col cols="10">
                  <v-text-field
                    ref="search"
                    label="Search"
                    v-model="search"
                    persistent-hint
                    hint="Enter as much info as you need to find your location on Google Maps and include the city/state"
                    @keydown="keyPress"
                    :error-messages="error && ['Refine your search. Include city, state and zip code']"
                  ></v-text-field>
                </v-col>
                <v-col class="text-center" cols="2" >
                  <v-fab-transition>
                    <v-btn fab small color="color3Text color3--text" @click.stop="gSearch" :disabled="!search">
                      <v-icon>fas fa-search</v-icon>
                    </v-btn>
                  </v-fab-transition>
                </v-col>
                <v-col cols="12" style="min-height: 350px">
                  <iframe width="100%" height="100%" frameborder="0" style="border:0" :src="googleUrl" allowfullscreen></iframe>
                </v-col>
              </v-row>
            </v-container>
          </v-window-item>
          <!-- Name it -->
          <v-window-item :value="1">
            <v-container class="pa-3">
              <v-row dense>
                <v-col cols="12" class="subheading">
                  Let's name your location
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    name="name"
                    label="Name"
                    v-model="name"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" class="subheading">
                  And assign a time zone
                </v-col>
                <v-col cols="12">
                  <v-select
                    :items="['Eastern Standard Time','Central Standard Time','Mountain Standard Time','Pacific Standard Time', 'Hawaiian Standard Time']"
                    v-model="timezone"
                    label="Timezone"
                    placeholder="Choose a timezone..."
                    color="color3"
                    item-color="color3"
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-window-item>
        </v-window>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="pa-3" style="min-height: 60px">
        <template v-if="currentStep === 0">
          <v-fab-transition>
            <div v-if="googleQ" class="title ml-3">
              Map look good?
            </div>
          </v-fab-transition>
          <v-spacer></v-spacer>
          <v-fab-transition>
            <v-btn
              key="yes"
              color="success"
              text
              v-if="googleQ && currentStep === 0"
              @click.stop="currentStep++"
            >
              yes
            </v-btn>
          </v-fab-transition>
          <v-fab-transition>
            <v-btn
              color="error"
              text
              v-if="googleQ"
              @click.stop="refine"
              key="no"
            >
              no
            </v-btn>
          </v-fab-transition>
        </template>
        <template v-else>
          <v-fab-transition>
            <v-btn
              color="color3Text color3--text"
              fab small
              @click.stop="currentStep--"
              :loading="saving"
            >
              <v-icon>fas fa-caret-left</v-icon>
            </v-btn>
          </v-fab-transition>
          <v-spacer key="saveSpace"></v-spacer>
          <v-fab-transition>
            <v-btn
              color="success white--text"
              fab small
              key="save"
              :disabled="!(name && timezone)"
              @click.stop="save"
              :loading="saving"
            >
              <v-icon>fas fa-save</v-icon>
            </v-btn>
          </v-fab-transition>
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  data () {
    return {
      dialog: false,
      currentStep: 0,
      search: null,
      name: null,
      timezone: null,
      googleQ: null,
      saving: false,
      error: null
    }
  },
  computed: {
    googleUrl () {
      return `https://www.google.com/maps/embed/v1/place?q=${this.googleQ || 'current%20location'}&key=AIzaSyCwaRA4_FE4ZiG4I3WrEvZWpeW7Ro8gx0M`
    }
  },
  methods: {
    gSearch () {
      this.googleQ = this.search ? encodeURIComponent(this.search) : null
    },
    keyPress (e) {
      if (e.keyCode === 13) {
        this.gSearch()
      }
    },
    save () {
      if (this.saving) return
      this.saving = true
      this.$VBL.post.location({
        name: this.name,
        googleUrl: this.googleUrl,
        timezone: this.timezone
      })
        .then(response => {
          this.$emit('location-created', response.data)
        })
        .catch(err => console.log(err.response))
        .finally(() => {
          this.saving = false
          this.dialog = false
        })
    },
    reset () {
      this.dialog = false
      this.currentStep = '1'
      this.search = null
      this.name = null
      this.timezone = null
      this.googleQ = null
      this.saving = false
      this.error = null
    },
    refine () {
      this.error = true
      this.$refs.search.focus()
    }
  },
  watch: {
    dialog: function (val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs.search.focus()
        })
      } else {
        this.reset()
      }
    }
  }
}
</script>

<style>

</style>
