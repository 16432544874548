<template>
    <v-container fluid class="pt-0">
      <v-row dense justify="end" v-if="!forceAdvanced || showCoachReg">
        <v-btn
          color="color3"
          class="xsmall"
          text
          v-if="showCoachReg"
          @click.stop="goCoaches({text: 'Spectators', value: 'spectators'})"
        >Make Coach/Spectator</v-btn>
        <v-btn
          v-if="!forceAdvanced"
          color="color3"
          class="xsmall"
          text
          @click.stop="advanced = !advanced"
        >go {{advanced ? 'simple' : 'advanced'}}</v-btn>
      </v-row>
      <v-expand-transition>
        <div v-if="advanced || forceAdvanced">
          <v-row dense>
            <!-- COACH OR SPECTATOR -->
            <v-col cols="12" v-if="isCS">
              <v-radio-group v-model="cs" row>
                <v-radio
                  v-for="opt in csOptions"
                  :key="opt.value"
                  color="color3"
                  :label="opt.text"
                  :value="opt.value"
                  return-object
                ></v-radio>
              </v-radio-group>
            </v-col>
            <!-- NAME -->
            <v-col cols="12">
              <v-text-field
                label="Division Name"
                v-model="division._Name"
                color="color3"
              ></v-text-field>
            </v-col>
            <!-- NUM OF PLAYERS -->
            <v-col cols="6" v-if="(isTournament || isLeague) && !isCS">
              <v-text-field
                color="color3"
                v-model="division.numOfPlayers"
                label="Number Of Players"
                type="number"
                hint="Not Doubles? Enter the number of player on a team"
                persistent-hint
                @blur="playerBlur"
              ></v-text-field>
            </v-col>
            <!-- NUM ON ROSTER -->
            <v-col cols="6" v-if="(isTournament || isLeague) && !isCS">
              <v-text-field
                v-model="division.numAllowedOnRoster"
                label="Number Allowed On Roster"
                type="number"
                hint="How many players are allowed on the roster?"
                persistent-hint
              ></v-text-field>
            </v-col>
            <!-- USE TEAM NAME -->
            <v-expand-transition>
              <v-col cols="12" v-if="!isCS" class="d-flex align-center">
                <v-switch
                  class="shrink mr-3"
                  label="Duals"
                  v-model="division.props"
                  value="lineups"
                  color="success"
                ></v-switch>
                <v-slide-x-transition>
                  <v-switch
                    v-if="division.props.includes('lineups')"
                    class="shrink"
                    label="Allow Empty Roster Registration"
                    v-model="division.props"
                    value="allowZeroPlayers"
                    color="success"
                  ></v-switch>
                </v-slide-x-transition>
                <v-switch
                  v-if="division.numOfPlayers <= 2"
                  class="shrink"
                  label="Use Team Names"
                  v-model="division.props"
                  value="useTeamNames"
                  color="success"
                ></v-switch>
              </v-col>
            </v-expand-transition>
            <v-alert
              :value="division.props.includes('lineups')"
              type="info"
              prominent text
              border="left"
              transition="scale-transition"
            >
              The dual count will be the number of players per team divided by 2. Please adjust accordingly.
              <br><br>
              <b>You current dual count will be {{division.numOfPlayers/2}}</b>
            </v-alert>
          </v-row>
        </div>
      </v-expand-transition>
      <v-row dense>
        <v-col cols="12" v-if="!isCS">
          <v-autocomplete
            v-model="division.ageType"
            :items="options.ageTypeOptions"
            item-text="name"
            item-value="id"
            label="Age Group*"
            return-object
            color="color3"
            item-color="color3"
            type="search"
            autocomplete="off"
          >
          </v-autocomplete>
        </v-col>
        <v-col cols="12" sm="6" v-if="!isCS">
          <v-autocomplete
            v-model="division.gender"
            :items="genders"
            item-text="name"
            item-value="id"
            label="Gender*"
            return-object
            color="color3"
            item-color="color3"
            type="search"
            autocomplete="off"
          >
          </v-autocomplete>
        </v-col>
        <v-col cols="12" sm="6" v-if="division.divisionRequired">
          <v-autocomplete
            v-model="division.division"
            :items="divisions"
            item-text="name"
            item-value="id"
            label="Division*"
            return-object
            :menu-props="{'closeOnClick':true, 'closeOnContentClick': true}"
            color="color3"
            item-color="color3"
            type="search"
            autocomplete="off"
          >
          </v-autocomplete>
        </v-col>
        <template
          v-if="!isXrefs"
        >
          <v-col cols="12" sm="6">
            <v-text-field
              :label="isCS ? 'Max Registrations' : `Max ${participants}`"
              v-model="division.maxTeams"
              type="number"
              color="color3"
            ></v-text-field>
          </v-col>
          <v-col cols="12" :sm="division.isTournament || division.isTryout ? 6 : 12">
            <v-checkbox :label="isCS ? 'Show Registrations' : `Show Registered ${participants}`" v-model="division.showTeams" color="color3"></v-checkbox>
          </v-col>
        </template>
      </v-row>
      <registration-window
        v-if="!isXrefs"
        hide-dates
        :window="division.registrationWindows[0]"
        :dialog="dialog"
        :hidePer="(!isTournament && !isLeague) || isCS"
        :tournament="tournament"
      ></registration-window>
      <v-row dense justify="end">
        <v-fab-transition>
          <v-btn
            v-if="saveable"
            color="color3 color3Text--text"
            class="mr-2"
            @click.native.stop="save"
            fab
          >
            <v-icon>fas fa-save</v-icon>
          </v-btn>
        </v-fab-transition>
        <v-btn
          class="error white--text"
          @click.native.stop="cancel"
          fab
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-row>
    </v-container>
</template>

<script>
import RegistrationWindow from './RegistrationWindow.vue'

export default {
  props: ['division', 'isNew', 'dialog', 'options', 'tournament', 'showCoachReg'],
  data () {
    return {
      date: false,
      checkin: false,
      play: false,
      oDto: null,
      advanced: false
    }
  },
  computed: {
    csOptions () {
      return [
        { text: 'Coaches', value: 'coach-college' },
        { text: 'Refs', value: 'externalRefs' },
        { text: 'Spectators', value: 'spectators' }
      ]
    },
    cs: {
      get () {
        return this.isSpectators ? 'spectators' : this.isCollegeCoaches ? 'coach-college' : this.isXrefs ? 'externalRefs' : null
      },
      set (v) {
        console.log(v)
        var opt = this.csOptions.find(f => f.value === v)
        this.division.props = this.division.props.filter(f => !['coach-college', 'spectators', 'externalRefs'].includes(f))
        this.division.props.push(opt.value)
        this.division._Name = opt.text
      }
    },
    forceAdvanced () {
      return this.division && (this.division._Name || this.division.numOfPlayers !== 2 || this.division.numAllowedOnRoster !== 2 || this.division.useTeamNames)
    },
    genders () {
      if (!this.division.ageType) return this.options.genderOptions

      return this.options.genderOptions.filter((opt) => { return opt.ageTypeId === this.division.ageType.id })
    },
    divisions () {
      if (!this.division.ageType) return this.options.divisionOptions

      return this.options.divisionOptions.filter((opt) => { return opt.ageTypeId === this.division.ageType.id })
    },
    title () {
      return this.division && this.division.id > 0 ? 'Edit Division' : 'Add New Division'
    },
    shortText () {
      const bp = this.$vuetify.breakpoint.name
      if (bp === 'xs') {
        return true
      }
      return false
    },
    dirty () {
      return this.isNew || JSON.stringify(this.oDto) !== JSON.stringify(this.division.dto)
    },
    saveable () {
      return this.dirty &&
        this.division.ageType &&
        this.division.gender &&
        (this.division.division || (!this.division.divisionRequired && this.division._Name)) &&
        this.division.registrationWindows[0].fee >= 0
    },
    isTournament () {
      return this.tournament.isTournament && this.division.isTournament
    },
    isLeague () {
      return this.tournament.isLeague
    },
    participants () {
      return this.division.participantType + 's'
    },
    isCoaches () {
      return this.division.isCoaches
    },
    isCollegeCoaches () {
      return this.division.isCollegeCoaches
    },
    isSpectators () {
      return this.division.isSpectators
    },
    isXrefs () {
      return this.division.isXrefs
    },
    isCS () {
      return this.division.isCS
    }
  },
  methods: {
    goCoaches (prop) {
      this.division.props.push('spectators')
      this.division._Name = 'Spectators'
      this.division.ageType = this.options.ageTypeOptions.find(f => f.id === 2)
      this.division.division = this.options.divisionOptions.find(f => f.id === 19)
      this.division.gender = this.options.genderOptions.find(f => f.id === 2)
      this.division.maxTeams = null
      this.division.registrationFields.fields = []
      this.division.registrationFields.requiredFields = ['highSchool,phone,email']
      this.division.registrationWindows[0].fee = 0
      this.division.registrationWindows[0].feeIsPerTeam = true
      this.division.numOfPlayers = 1
      this.division.numAllowedOnRoster = 1
    },
    save () {
      this.division.props = this.division.props.filter(f => !f.startsWith('lineups-dual-'))
      if (this.division.lineups) {
        this.division.props.push(`lineups-dual-${this.division.numOfPlayers / 2}`)
      }
      this.$emit('save')
    },
    cancel () {
      this.division.update(this.oDto)
      this.$emit('cancel')
    },
    selectAge (ageTypeId) {
      if (this.division.ageType && this.division.ageType.Id === ageTypeId) return

      this.division.ageType = this.options.ageTypeOptions.find((opt) => { return opt.id === ageTypeId })
    },
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${month}/${day}/${year}`
    },
    parseDate (date) {
      if (!date) return null

      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    playerBlur () {
      const val = this.division.numOfPlayers
      if (val) {
        if (this.division.numAllowedOnRoster < val) this.division.numAllowedOnRoster = val
      } else {
        this.division.numOfPlayers = this.division.numAllowedOnRoster = 2
      }
    }
  },
  watch: {
    division: function (n, o) {
      if (n) {
        this.oDto = this.division.dto
      }
    },
    'division.ageType': {
      handler: function (val) {
        if (!val) return
        if (this.division.gender && this.division.gender.ageTypeId !== val.id) {
          this.division.gender = null
        }
        if (this.division.division && this.division.division.ageTypeId !== val.id) {
          this.division.division = null
        }
      },
      deep: true
    },
    'division.gender': {
      handler: function (val) {
        if (!val) return
        this.selectAge(val.ageTypeId)
      },
      deep: true
    },
    'division.division': {
      handler: function (val) {
        if (!val) return
        this.selectAge(val.ageTypeId)
      },
      deep: true
    }
  },
  components: {
    RegistrationWindow
  },
  mounted () {
    if (this.division) {
      this.oDto = this.division.dto
    }
  }
}
</script>

<style>

</style>
