<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="500px"
    transition="dialog-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn color="color3" text v-on="on" class="xsmall" @click.stop="dialog = true">
        <v-icon class="mr-2">fas fa-external-link-alt</v-icon>
        {{tournament.externalRegistrationUrl ? 'Edit' : 'Add'}} External Registration
      </v-btn>
    </template>
   <v-card>
    <v-toolbar color="color1 color1Text--text">
      <v-toolbar-title>External Registration</v-toolbar-title>
    </v-toolbar>
    <v-container>
      <v-row dense>
        <v-col cols="12">
          <v-alert type="info" :value="true">
            By adding an external registration URL, you will be turning off all VolleyballLife.com's registration features.
            Including, but not limited to: registration deadlines, automatic refund/withdraw, AAU# validation, player profile mapping, tournament team contact, and automatic wait-listing.
          </v-alert>
        </v-col>
        <v-col cols="12">
          <v-checkbox color="color3" label="I understand I will be losing all registration features" v-model="verified"></v-checkbox>
        </v-col>
        <v-col cols="12">
          <v-text-field
            name="url"
            label="External Registration URL*"
            v-model="url"
            :error-messages="urlErrors"
            @blur="$v.url.$touch()"
            hint="Add the complete URL i.e. https://www.myregistration.com?tournament=0"
            persistent-hint
            :disabled="!verified"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-tooltip top v-if="tournament.externalRegistrationUrl">
            <span>Remove</span>
            <template v-slot:activator="{ on }">
              <v-fab-transition>
                <v-btn
                  v-on="on"
                  color="white error--text"
                  class="mr-0"
                  fab
                  @click.stop="clear"
                >
                  <v-icon>fas fa-ban</v-icon>
                </v-btn>
              </v-fab-transition>
            </template>
          </v-tooltip>
        </v-col>
        <v-col class="text-right" cols="8" >
          <v-tooltip top>
            <span>Save</span>
            <template v-slot:activator="{ on }">
              <v-fab-transition>
                <v-btn
                  v-on="on"
                  color="color3 color3Text--text"
                  class="mr-2"
                  v-if="saveable"
                  fab
                  @click.stop="save"
                >
                  <v-icon>fas fa-save</v-icon>
                </v-btn>
              </v-fab-transition>
            </template>
          </v-tooltip>
          <v-tooltip top>
            <span>Cancel</span>
            <template v-slot:activator="{ on }">
              <v-fab-transition>
                <v-btn
                  v-on="on"
                  color="error white--text"
                  fab
                  @click.stop="dialog = false"
                >
                  <v-icon>fas fa-times</v-icon>
                </v-btn>
              </v-fab-transition>
            </template>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-container>
   </v-card>
  </v-dialog>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, url } from 'vuelidate/lib/validators'

export default {
  props: ['tournament'],
  mixins: [validationMixin],
  validations () {
    return {
      url: { required, url }
    }
  },
  data () {
    return {
      dialog: false,
      url: null,
      verified: false
    }
  },
  computed: {
    dirty () {
      return this.url !== this.tournament.externalRegistrationUrl
    },
    saveable () {
      return this.verified && this.dirty && !this.$v.$invalid
    },
    urlErrors () {
      const errors = []
      if (!this.$v.url.$dirty) return errors
      !this.$v.url.url && errors.push('Please enter a valid URL')
      !this.$v.url.required && errors.push('A URL is required')
      return errors
    }
  },
  methods: {
    save () {
      if (this.saveable) {
        this.tournament.externalRegistrationUrl = this.url
        this.dialog = false
      }
    },
    clear () {
      if (this.tournament.externalRegistrationUrl) {
        this.tournament.externalRegistrationUrl = null
        this.dialog = false
      }
    }
  },
  watch: {
    dialog: function (val) {
      if (val && this.tournament.externalRegistrationUrl) {
        this.url = this.tournament.externalRegistrationUrl
        this.verified = true
      } else {
        this.url = null
        this.verified = false
        this.$v.$reset()
      }
    }
  }
}
</script>

<style>

</style>
