<template>
  <v-card>
    <v-toolbar color="color2 color2Text--text">
      <v-toolbar-title>Fee Calculator</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn color="color1 color1Text--text" @click.stop="$emit('close-click')" small fab>
        <v-icon>fas fa-times</v-icon>
      </v-btn>
    </v-toolbar>
    <loading-bar :value="loading"></loading-bar>
    <v-expand-transition>
      <v-card-text v-if="!loading">
        <v-container fluid>
          <v-row dense>
            <v-col cols="6">
              <v-text-field
                label="Fee to customer"
                number
                v-model="gross"
                @input="calcNet"
                hint="Change this to calculate your net"
                persistent-hint
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                label="Net to you"
                number
                @input="calcGross"
                v-model="net"
                hint="Change this to calculate what fee to charge"
                persistent-hint
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-data-table
                :headers="[{text: 'Fee', value: 'label'}, {text: 'Amount', value: 'value'}]"
                :items="feeDetails"
                hide-default-footer
                :items-per-page="-1"
                dense
                :mobile-breakpoint="-1"
              >
                <template v-slot:[`item.value`]={item}>
                  {{item.value | fixed2Up}}
                </template>
              </v-data-table>
            </v-col>
            <v-col cols="12" class="caption">*This calculator always rounds up and may not be 100% accurate</v-col>
          </v-row>
          <v-row v-if="!window || window .id === 0">
            <v-col cols="12">
              <v-alert type="warning" :value="true">
                Unsaved Event
              </v-alert>
              <div>
                Until the event is saved, the calculator cannot access all the information needed to calculate the correct fee type. Please select a type below.
              </div>
            </v-col>
            <v-col cols="12">
              <v-select
                label="Event Type"
                :items="fees"
                v-model="selectedFee"
                item-text="itemName"
                return-object
                @change="lastAction"
              ></v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-expand-transition>
      <v-divider></v-divider>
      <v-card-actions class="justify-center">
        <v-btn
          color="color3 color3Text--text"
          :disabled="window.fee === gross"
          @click.stop="useIt"
        >Use This fee</v-btn>
      </v-card-actions>
  </v-card>
</template>

<script>
import sumBy from 'lodash.sumby'
import { fixed2Up } from '@/Filters'

export default {
  props: ['username', 'tournament', 'visible', 'window'],
  data () {
    return {
      loading2: true,
      loading: true,
      gross: null,
      net: null,
      fees: [],
      feeDetails: [],
      selectedFee: null,
      lastAction: this.calcNet
    }
  },
  methods: {
    getFees () {
      this.loading = true
      this.$VBL.organization.fees.get(this.username, this.window.id)
        .then(r => {
          this.fees = r.data
          if (this.window) {
            this.gross = this.window.fee
            this.calcNet()
          }
        })
        .catch(e => console.log(e.response))
        .finally(() => { this.loading = false })
    },
    calcNet () {
      const t = this.tournament
      const s = this.selectedFee
      this.feeDetails = [
        { label: 'Stripe % Fee', value: this.gross * 0.029 },
        { label: 'Stripe Fixed Fee', value: this.gross > 0 ? 0.30 : 0 },
        s ? { label: s.itemName, value: s.amount } : false,
        ...this.fees.map(f => s ? false : t[f.test] ? { label: f.itemName, value: f.amount } : false)
      ].filter(f => f)
      this.net = this.gross - sumBy(this.feeDetails, 'value')
      this.lastAction = this.calcNet
    },
    calcGross () {
      const t = this.tournament
      const s = this.selectedFee
      this.feeDetails = [
        { label: 'Stripe Fixed Fee', value: this.gross > 0 ? 0.30 : 0 },
        s ? { label: s.itemName, value: s.amount } : false,
        ...this.fees.map(f => s ? false : t[f.test] ? { label: f.itemName, value: f.amount } : false)
      ].filter(f => f)
      const fixed = sumBy(this.feeDetails, 'value') + (+this.net)
      this.gross = fixed2Up(fixed / (1 - 0.029))
      this.feeDetails.unshift({ label: 'Stripe % Fee', value: this.gross * 0.029 })
      this.lastAction = this.calcGross
    },
    init () {
      this.gross = null
      this.net = null
      this.fees = []
      this.feeDetails = []
      if (this.visible) this.getFees()
    },
    useIt () {
      this.$emit('new-fee', this.gross)
    }
  },
  watch: {
    visible: 'init'
  },
  mounted () {
    this.init()
  }
}
</script>
