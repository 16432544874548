<template>
  <v-row dense>
    <v-col  cols="12">
      <v-data-table
        :headers="divisionHeaders"
        :items="divisions"
        item-key="key"
        hide-default-footer
        dense
        :options="{itemsPerPage: -1}"
      >
        <template v-slot:[`item.division.name`]="{ item }">
          {{(item.isTournament || item.isTryout || item.isLeague) && item.division && !item.isCS ? item.division.name : null}}
        </template>

        <template v-slot:[`item.ageType.name`]="{ item }">
          {{ item.ageType && !item.isCS ? item.ageType.name : null}}
        </template>
        <template v-slot:[`item.gender.name`]="{ item }">
          {{ item.gender && !item.isCS ? item.gender.name : null}}
        </template>

        <template v-slot:[`item.maxTeams`]="{ item }">
          {{item.maxTeams}}
          <v-icon v-if="!item.maxTeams">fas fa-infinity</v-icon>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            v-if="!viewOnly"
            fab
            small text
            color="color3"
            :loading="busy"
            class="mr-1"
            @click="editDivision(item)">
            <v-icon small>fas fa-pencil-alt</v-icon>
          </v-btn>
          <v-btn
            v-if="!viewOnly && divisions.length > 1"
            fab
            small text
            color="error"
            :loading="busy"
            @click="deleteDivision(item)">
            <v-icon small>fas fa-trash</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-col>
    <v-col cols="12" class="text-right">
      <v-btn
        v-if="!viewOnly"
        text
        class="color3--text"
        :loading="busy"
        @click.stop="addDivision"
      >
        Add A Division
      </v-btn>
    </v-col>

    <v-dialog v-model="editDialog" max-width="500px" persistent scrollable>
      <v-card v-if="selectedDivision">
        <v-toolbar color="color2 color2Text--text">
          <v-toolbar-title>{{selectedDivision.id > 0 ? 'Edit Division' : 'Add New Division'}}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <division-simple
            v-if="selectedDivision"
            :division="selectedDivision"
            @save="saveDivision"
            @cancel="cancelEdit"
            :isNew="isNew"
            :dialog="editDialog"
            :options="options"
            :tournament="tournament"
            :showCoachReg="showCoachReg"
          ></division-simple>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Division from '@/classes/TournamentDivision'
import DivisionSimple from './DivisionSimple.vue'
import min from 'lodash.min'
import { mapGetters } from 'vuex'

export default {
  props: ['tournament', 'busy', 'viewOnly', 'options'],
  data () {
    return {
      selectedDivision: null,
      editDialog: false
    }
  },
  computed: {
    ...mapGetters(['user', 'view']),
    org () {
      return this.tournament && this.tournament.organization && this.user && this.user.pages.find(f => f.id === this.tournament.organization.id)
    },
    showCoachReg () {
      return !this.viewOnly && this.org && this.org.props.includes('coaches')
    },
    divisions () {
      var divs = [...this.tournament.publicDivisions]
      if (this.view.admin) {
        divs.push(...this.tournament.refDivisions)
      }
      return divs
    },
    showName () {
      return !!this.divisions.find(f => f._Name)
    },
    showDivision () {
      return !!this.divisions.find(f => f.isTournament || f.isTryout)
    },
    divisionHeaders () {
      var heads = [
        { text: 'Name', value: 'name', align: 'left', hide: !this.showName },
        { text: 'Type', value: 'ageType.name', align: 'left' },
        { text: 'Gender', value: 'gender.name', align: 'left' },
        { text: 'Division', value: 'division.name', align: 'left', hide: !this.showDivision },
        { text: 'Entry', value: 'currentEntryFee.display', align: 'left' },
        { text: `Max ${this.tournament.participantType}s`, value: 'maxTeams', align: 'center' },
        { text: '', value: 'actions', align: 'right', sortable: false }
      ]

      return heads.filter(f => !f.hide)
    },
    isNew () {
      return this.selectedDivision && !this.divisions.find(f => f.id === this.selectedDivision.id)
    },
    template () {
      var d = this.divisions.filter(f => !f.isCS)
      if (d.length === 0) {
        d = this.divisions
      }
      return d[d.length - 1].dto
    }
  },
  methods: {
    addDivision (coaches) {
      const d = new Division(this.$VBL, JSON.stringify(this.template))
      const minId = this.divisions.length > 0 ? min(this.divisions.map(m => m.id)) : 0
      d.id = minId > 0 ? 0 : minId - 1
      d.division = null
      d._Name = null
      d.days = [d.days[0]]
      d.days.forEach(f => {
        f.id = 0
        f.brackets = []
        f.flights = []
      })
      d.registrationFields.id = 0
      d.registrationWindows.forEach((w) => {
        w.id = 0
      })
      d.props = d.props.filter(f => f !== 'coach')
      if (coaches === true) {
        d.props.push('coach')
        d.ageType = this.options.ageTypeOptions.find(f => f.id === 2)
        d.division = this.options.divisionOptions.find(f => f.id === 19)
        d.gender = this.options.genderOptions.find(f => f.id === 2)
        d.maxTeams = null
        d.registrationFields.fields = []
        d.registrationFields.requiredFields = ['highSchool,phone,email']
        d.registrationWindows[0].fee = 0
        d.registrationWindows[0].feeIsPerTeam = true
        this.tournament.divisions.push(d)
        return
      }
      this.editDivision(d)
    },
    addCoachesDivision () {
      this.addDivision(true)
    },
    deleteDivision (division) {
      if (division.id > 0) {
        division.canceled = true
      } else {
        const i = this.tournament.divisions.findIndex(f => f.id === division.id)
        if (i > -1) {
          this.tournament.divisions.splice(i, 1)
        }
      }
    },
    editDivision (division) {
      if (division) {
        this.selectedDivision = division
        this.editDialog = true
      }
    },
    cancelEdit () {
      this.editDialog = false
    },
    saveDivision () {
      if (this.isNew) {
        this.tournament.divisions.push(this.selectedDivision)
      }
      this.cancelEdit()
    }
  },
  components: {
    'division-simple': DivisionSimple
  }
}
</script>

<style scoped>
.deleted {
  text-decoration: line-through;
}
</style>
